import React from "react";

import { Phase, Task, UserProfile } from "@palette.tools/model.client";
import { Item, Group } from "@palette.tools/react.dogs";
import { getProfileName, TaskStatus } from "@palette.tools/model";
import { addDays, max, min } from 'date-fns';

const DAY_IN_MS = 86400000;

export type TaskRow = {
  rowType: 'task',
  id: string;
  title: string;
  duration: number;
  end: Date;
  updated_at: number;
  parent_phase_id: string;
  status: TaskStatus;
}

export type ProfileRow = {
  rowType: 'profile',
  id: string;
  title: string;
  duration: number;
  end: Date;
  image_url?: string;
  updated_at: number;
  parent_phase_id: string;
}

export type PhaseRow = {
  rowType: 'phase',
  id: string;
  title: string;
  duration: number;
  end: Date;
  updated_at: number;
}

type UnassignedRow = {
  rowType: 'unassigned',
  id: string;
  title: string;
  duration: number;
  end: Date;
  updated_at: number;
}

export type PhaseTimelineRow = PhaseRow | ProfileRow | TaskRow | UnassignedRow;

export const constructTimelineTree = (
  {
    phases,
    profilesByPhase,
    tasksByPhase,
    tasksByProfile,
    profilesByTask,
    showCompletedTasks,
    optimisticUpdates,
    isPreviewingUpdates,
  }:
  {
  phases: Phase[],
  profilesByPhase: Record<string, UserProfile[]>,
  tasksByPhase: Record<string, Task[]>,
  tasksByProfile: Record<string, Task[]>,
  profilesByTask: Record<string, UserProfile[]>,
  showCompletedTasks?: boolean,
  optimisticUpdates: Record<string, {
    id: string,
    estimate: number,
    deadline: number,
    updated_at: number,
  }>,
  isPreviewingUpdates: boolean,
}): [(Item<PhaseTimelineRow> | Group<PhaseTimelineRow>)[], Record<string, PhaseTimelineRow>, Record<string, string[]>, number] => {

  let lastModified = 0;

  const getMinimumValue = (key: 'estimate' | 'deadline', value: number | null | undefined) => {
    if (value !== null && value !== undefined) return value;
    if (key === 'deadline') return addDays(Date.now(), 1).getTime();
    return DAY_IN_MS;
  };

  const getOptimisticValue = (id: string, key: 'estimate' | 'deadline' | 'updated_at', defaultValue: number) => {
    return optimisticUpdates[id] ? optimisticUpdates[id][key] : defaultValue;
  };

  const getSortValue = (id: string, key: 'estimate' | 'deadline', defaultValue: number) => {
    return isPreviewingUpdates ? defaultValue : getOptimisticValue(id, key, defaultValue);
  };

  return phases
    .sort((a, b) => {
      const aEnd = new Date(getSortValue(a.id, 'deadline', a.data.deadline || Date.now()));
      const bEnd = new Date(getSortValue(b.id, 'deadline', b.data.deadline || Date.now()));
      if (aEnd.getTime() !== bEnd.getTime()) return aEnd.getTime() - bEnd.getTime();
      
      const aStart = new Date(aEnd.getTime() - getSortValue(a.id, 'estimate', a.data.estimate || 0));
      const bStart = new Date(bEnd.getTime() - getSortValue(b.id, 'estimate', b.data.estimate || 0));
      if (aStart.getTime() !== bStart.getTime()) return aStart.getTime() - bStart.getTime();
      
      if (a.data.name && b.data.name && a.data.name !== b.data.name) return a.data.name.localeCompare(b.data.name);
      
      return a.id.localeCompare(b.id);
    })
    .reduce((acc, phase) => {
      const [items, rowsById, ancestorsById] = acc;
      ancestorsById[phase.id] = [];
      const unassignedRowId = `${phase.id}-unassigned`;
      ancestorsById[unassignedRowId] = [phase.id, ...(ancestorsById[phase.id] || [])];
      lastModified = Math.max(lastModified, phase.data.updated_at || 0);

      const phaseRow = {
        __id: phase.id,
        data: {
          rowType: 'phase' as const,
          id: phase.id,
          title: phase.data.name || "",
          duration: getOptimisticValue(phase.id, 'estimate', phase.data.estimate || 0),
          end: new Date(getOptimisticValue(phase.id, 'deadline', phase.data.deadline || Date.now())),
          updated_at: getOptimisticValue(phase.id, 'updated_at', phase.data.updated_at || 0),
        },
        __children: [
          ...(profilesByPhase[phase.id] || [])
            .sort((a, b) => {
              const aName = getProfileName(a);
              const bName = getProfileName(b);
              if (aName !== bName) return aName.localeCompare(bName);
              return a.id.localeCompare(b.id);
            })
            .map(profile => {
              const profileRowId = `${phase.id}-${profile.id}`;
              ancestorsById[profileRowId] = [phase.id, ...(ancestorsById[phase.id] || [])];
              const profileTasks = (tasksByProfile[profile.id] || []).filter(task => profilesByTask[task.id]?.[0]?.id === profile.id); // Only the first assignee
              const phaseTasks = profileTasks.filter(task => tasksByPhase[phase.id]?.some(x => x.id === task.id));
              lastModified = Math.max(lastModified, profile.data.updated_at || 0);

              const { profileStart, profileEnd } = phaseTasks.reduce(
                ({ profileStart, profileEnd }, task) => {
                  const taskDeadline = getOptimisticValue(task.id, 'deadline', task.data.deadline || Date.now());
                  const taskEstimate = getOptimisticValue(task.id, 'estimate', task.data.estimate || 0);
                  if (!taskDeadline || !taskEstimate) return { profileStart, profileEnd };
                  const start = new Date(taskDeadline - taskEstimate);
                  const end = new Date(taskDeadline);
                  return {
                    profileStart: !profileStart ? start : min([profileStart, start]),
                    profileEnd: !profileEnd ? end : max([profileEnd, end]),
                  };
                },
                { profileStart: null, profileEnd: null } as { profileStart: Date | null, profileEnd: null | Date }
              );

              const profileRow = {
                __id: profileRowId,
                data: {
                  rowType: 'profile' as const,
                  id: profile.id,
                  title: getProfileName(profile),
                  duration: profileStart && profileEnd ? profileEnd.getTime() - profileStart.getTime() : 0,
                  end: profileEnd || new Date(),
                  image_url: profile.data.image_url,
                  updated_at: getOptimisticValue(profile.id, 'updated_at', profile.data.updated_at || 0),
                  parent_phase_id: phase.id,
                },
                __children: phaseTasks
                  .sort((a, b) => {
                    const aEnd = new Date(getSortValue(a.id, 'deadline', getMinimumValue('deadline', a.data.deadline)));
                    const bEnd = new Date(getSortValue(b.id, 'deadline', getMinimumValue('deadline', b.data.deadline)));
                    if (aEnd.getTime() !== bEnd.getTime()) return aEnd.getTime() - bEnd.getTime();
                    
                    const aStart = new Date(aEnd.getTime() - getSortValue(a.id, 'estimate', getMinimumValue('estimate', a.data.estimate)));
                    const bStart = new Date(bEnd.getTime() - getSortValue(b.id, 'estimate', getMinimumValue('estimate', b.data.estimate)));
                    if (aStart.getTime() !== bStart.getTime()) return aStart.getTime() - bStart.getTime();
                    
                    if (a.data.name && b.data.name && a.data.name !== b.data.name) return a.data.name.localeCompare(b.data.name);
                    
                    return a.id.localeCompare(b.id);
                  })
                  .map(task => {
                    if (!showCompletedTasks && task.data.status === TaskStatus.Complete) return;
                    ancestorsById[task.id] = [profileRowId, ...(ancestorsById[profileRowId] || [])];
                    lastModified = Math.max(lastModified, task.data.updated_at || 0);

                    const taskRow = {
                      __id: task.id,
                      data: {
                        rowType: 'task' as const,
                        id: task.id,
                        title: task.data.name || "",
                        duration: getOptimisticValue(task.id, 'estimate', getMinimumValue('estimate', task.data.estimate)),
                        end: new Date(getOptimisticValue(task.id, 'deadline', getMinimumValue('deadline', task.data.deadline))),
                        updated_at: getOptimisticValue(task.id, 'updated_at', task.data.updated_at || 0),
                        parent_phase_id: phase.id,
                        status: task.data.status || TaskStatus.NotStarted,
                      },
                    };
                    rowsById[task.id] = taskRow.data;
                    return taskRow;
                  }).filter(Boolean) as (Item<PhaseTimelineRow>)[],
              };
              rowsById[profileRow.__id] = profileRow.data;
              return profileRow;
            }),
          {
            __id: unassignedRowId,
            data: {
              rowType: 'unassigned' as const,
              id: unassignedRowId,
              title: "Unassigned",
              duration: 0,
              end: new Date(),
              updated_at: getOptimisticValue(unassignedRowId, 'updated_at', 0),
            },
            __children: (tasksByPhase[phase.id] || [])
              .filter(task => !profilesByTask[task.id]?.map(Boolean).includes(true))
              .sort((a, b) => {
                const aEnd = new Date(getSortValue(a.id, 'deadline', a.data.deadline || Date.now()));
                const bEnd = new Date(getSortValue(b.id, 'deadline', b.data.deadline || Date.now()));
                if (aEnd.getTime() !== bEnd.getTime()) return aEnd.getTime() - bEnd.getTime();
                
                const aStart = new Date(aEnd.getTime() - getSortValue(a.id, 'estimate', a.data.estimate || 0));
                const bStart = new Date(bEnd.getTime() - getSortValue(b.id, 'estimate', b.data.estimate || 0));
                if (aStart.getTime() !== bStart.getTime()) return aStart.getTime() - bStart.getTime();
                
                if (a.data.name && b.data.name && a.data.name !== b.data.name) return a.data.name.localeCompare(b.data.name);
                
                return a.id.localeCompare(b.id);
              })
              .map(task => {
                if (!showCompletedTasks && task.data.status === TaskStatus.Complete) return;
                ancestorsById[task.id] = [unassignedRowId, ...(ancestorsById[unassignedRowId] || [])];
                lastModified = Math.max(lastModified, task.data.updated_at || 0);

                const taskRow = {
                  __id: task.id,
                  data: {
                    rowType: 'task' as const,
                    id: task.id,
                    title: task.data.name || "",
                    duration: getOptimisticValue(task.id, 'estimate', task.data.estimate || 0),
                    end: new Date(getOptimisticValue(task.id, 'deadline', task.data.deadline || Date.now())),
                    updated_at: getOptimisticValue(task.id, 'updated_at', task.data.updated_at || 0),
                    parent_phase_id: phase.id,
                    status: task.data.status || TaskStatus.NotStarted,
                  },
                };
                rowsById[task.id] = taskRow.data;
                return taskRow;
              }).filter(Boolean) as (Item<PhaseTimelineRow>)[],
          },
        ],
      };
      
      items.push(phaseRow);
      rowsById[phase.id] = phaseRow.data;
      rowsById[`${phase.id}-unassigned`] = phaseRow.__children[phaseRow.__children.length - 1]!.data;
      
      return [items, rowsById, ancestorsById, lastModified];
    }, [[], {}, {}, 0] as [(Item<PhaseTimelineRow> | Group<PhaseTimelineRow>)[], Record<string, PhaseTimelineRow>, Record<string, string[]>, number]);

};


export const usePhaseTimeline = (
  phases: Phase[],
  profilesByPhase: Record<string, UserProfile[]>,
  tasksByPhase: Record<string, Task[]>,
  tasksByProfile: Record<string, Task[]>,
  profilesByTask: Record<string, UserProfile[]>,
  showCompletedTasks?: boolean,
) => {

  // Preview optimistic updates
  const [isPreviewingUpdates, setIsPreviewingUpdates] = React.useState(false);
  const lastOptimisticUpdate = React.useRef(0);
  const [optimisticUpdates, setOptimisticUpdates] = React.useState<Record<string, {
    id: string,
    estimate: number,
    deadline: number,
    updated_at: number,
  }>>({});

  const [items, rowsById, ancestorsById, lastModified] = React.useMemo(() => {
    return constructTimelineTree(
      {
        phases,
        profilesByPhase,
        tasksByPhase,
        tasksByProfile,
        profilesByTask,
        showCompletedTasks,
        optimisticUpdates,
        isPreviewingUpdates,
      }
    );
  }, [phases, profilesByPhase, tasksByPhase, tasksByProfile, profilesByTask, showCompletedTasks, optimisticUpdates, isPreviewingUpdates]);

  React.useEffect(() => {
    if (lastModified != lastOptimisticUpdate.current && !isPreviewingUpdates) {
      lastOptimisticUpdate.current = lastModified;
      setOptimisticUpdates({});
    }
  }, [lastModified, isPreviewingUpdates]);

  return {
    items,
    rowsById,
    ancestorsById,
    lastModified,
    setOptimisticUpdates: React.useCallback((updates: Record<string, {
      id: string,
      estimate: number,
      deadline: number,
    }>) => {
      setIsPreviewingUpdates(false);
      const updated_at = Date.now();
      setOptimisticUpdates(Object.fromEntries(Object.entries(updates).map(([id, update]) => [id, { ...update, updated_at }])));
    }, [setOptimisticUpdates]),
    setPreviewUpdates: React.useCallback((updates: Record<string, {
      id: string,
      estimate: number,
      deadline: number,
    }>) => {
      if (Object.keys(updates).length === 0) {
        setIsPreviewingUpdates(false);
      } else {
        const updated_at = Date.now();
        setOptimisticUpdates(Object.fromEntries(Object.entries(updates).map(([id, update]) => [id, { ...update, updated_at }])));
        setIsPreviewingUpdates(true);
      }
    }, [setOptimisticUpdates, setIsPreviewingUpdates]),
  };
}
