"use client";

import React, { ElementType, MouseEventHandler } from 'react';
import { Button, buttonVariants } from '../shadcn/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../shadcn/components/ui/dropdown-menu';
import { overrideElement } from '../utils';
import { VariantProps } from 'class-variance-authority';
import { cn } from '../shadcn/lib/utils';
import { ChevronDownIcon } from 'lucide-react';


export interface SplitButtonAction {
  label: string;
  isDefault?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  className?: string;
}

interface SplitButtonCustomProps extends VariantProps<typeof buttonVariants> {
  actions?: SplitButtonAction[];
  dropdownButtonClassName?: string;
  mainButtonClassName?: string;
  onClickMainButton?: MouseEventHandler<HTMLButtonElement>;
}

export const SplitButton = overrideElement<'div', SplitButtonCustomProps>(({
  props,
  children,
  className,
  customProps: {
    actions,
    dropdownButtonClassName,
    mainButtonClassName,
    onClickMainButton,
    variant = 'outline',
  },
}) => {

  return <div className={cn("flex items-center", className)} {...props} >
    <Button variant={variant} className={cn('rounded-r-none', mainButtonClassName)} onClick={onClickMainButton} >
      {children}
    </Button>
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={variant} className={cn('rounded-l-none border-l-0 px-2', dropdownButtonClassName)}>
          <ChevronDownIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {actions?.map((action, i) => (
          <DropdownMenuItem key={`${action.label}_${i}`} onClick={action.onClick} className={action.className}>
            {action.icon}
            {action.label}
            {action.isDefault
              ? <><span className="ml-6 text-xs uppercase text-muted-foreground">DEFAULT</span></>
              : null
            }
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
}, [
  'actions',
  'dropdownButtonClassName',
  'onClickMainButton',
  'mainButtonClassName',
  'variant',
]);